body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiCard-root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiCardActionArea-root {
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
}

.rodal,
.rodal-mask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed !important;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.rodal-dialog {
  display: flex;
  margin: auto;
  height: auto !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  /* padding: 1.25em; */
  padding: 0 !important;
  border-radius: 0.3125em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative !important;
  width: 32em;
  max-width: 100%;
}

